/**
 * colors
 */

$white: #FFFFFF;
$orange: #F9A541;
$lightGray: #CCCCCC;
$mediumGray: #999999;
$darkGray: #666666;
$superDarkGray: #333333;
$red: #C44331;
$backGray: #5C5C5C;

/**
 * Fontshttps://samples.myfonts.net/b_92/u/ff/672e680b61dbcb7faa89e5fe8d9267.gif
 */

@font-face{
	font-family: 'Novecento DemiBold';
    src: url('../fonts/Novecentowide-DemiBold.otf');
}

@font-face{
	font-family: 'Novecento Bold';
    src: url('../fonts/Novecentowide-Bold.otf');
}

@font-face{
	font-family: 'Novecento Normal';
    src: url('../fonts/Novecentowide-Normal.otf');
}

@font-face{
	font-family: 'Bebas Neue';
    src: url('../fonts/BebasNeue.otf'); 
}

@font-face{
	font-family: 'Dry Brush';
    src: url('../fonts/DryBrush.ttf'); 
}


.open-sans{
	font-family: "Open Sans";
}

.novecento-normal{
	font-family: 'Novecento Normal';
}

.novecento{
	font-family: 'Novecento DemiBold';
}

.bebas-neue{
	font-family: 'Bebas Neue';
}

.dry-brush{
	font-family: 'Dry Brush';
}

/**
 * Transitions Animations CSS3
 */

/**
 * Global Styles
 */

html, body{
	background-color: $white;
	@extend .novecento;
	color: $white;
	overflow-x: hidden;
}

body{
	overflow: hidden;
}

.no-list{
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

h1,h2,h3,h4,h5,h6,p{
	margin: 0; padding: 0;
}

.show-if-is-mobile{
	display: none;
}

.bg-image-default{
	width: 100%;
	height: auto;
}

// rating stars

.rating-list{
	@extend .no-list;
	&__item{
		float:left;
		width: 16px;
		height: 15px;
		margin: 0 3px 0 0;
		&--star-complete{
			background: url('../images/rating-stars/complete-star.png') repeat-x left bottom scroll transparent;
		}
		&--star-half{
			background: url('../images/rating-stars/half-star.png') repeat-x left bottom scroll transparent;
		}
	}
}

/*
	HEADER
 */

.header{
	width:100%;
	height: 864px;
	position: relative;
	&__bg-image__wrap{
		position: absolute;
		overflow: hidden;
		left: 0;
		right: 0;
		margin: auto;
		height: 864px;
		width: 1300px;
		z-index: 0;
	}
	&__bg-img{
		width: 100%;
		height: auto;
		-webkit-transition: all 2.5s ease; /* Safari and Chrome */
	    -moz-transition: all 2.5s ease; /* Firefox */
	    -ms-transition: all 2.5s ease; /* IE 9 */
	    -o-transition: all 2.5s ease; /* Opera */
	    transition: all 2.5s ease;
	    -moz-transform: scale(1,1);
		-webkit-transform: scale(1,1);
		transform: scale(1,1);
		 -moz-background-size: cover;
		-webkit-background-size: cover;
		background-size: cover;
		&--zoom{
	    	-moz-transform: scale(1.1, 1.1);
		    -webkit-transform: scale(1.1, 1.1);
		    transform: scale(1.1, 1.1);
	    }
	}
	&__bg-image{
		background: no-repeat center center scroll transparent;
		height: 864px;
		width: 100%;
		-moz-transition: all 2.5s ease-in;
	    -webkit-transition: all 2.5s ease-in;
	    transition: all 2.5s ease-in;
	    -moz-transform: scale(1,1);
	    -webkit-transform: scale(1,1);
	    transform: scale(1,1);
	     -moz-background-size: cover;
	    -webkit-background-size: cover;
	    background-size: cover;
	    &-zoom{
	    	-moz-transform: scale(1.1, 1.1);
		    -webkit-transform: scale(1.1, 1.1);
		    transform: scale(1.1, 1.1);
	    }
	}
	
	&__background-image{
		height: 864px;
		z-index: 2;
		position: relative;
	}

	&__background-screen{
		background: rgba(0, 0, 0, 0.7); 
		height: 100%;
		width: 100%;
	}
	&__wrapper{
		height: 864px;
		width: 1000px;
		margin: 0 auto;
		overflow: hidden;
	}
}

// FLOATING NAVIGATION

.floating-nav__wrapper{
	position: fixed;
	top: 0;
	z-index: 10;
	display: none;
	width: 100%;
	padding: 0;
}

.floating-nav__sub-wrapper{
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9);
}

.floating-nav__shadow{
	width: 100%;
	height: 5px;
	background: url('../images/shadows/border-black-shadow.png') repeat-x left bottom scroll transparent;
}

.floating-nav{
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
	&__list{
		@extend .no-list;
		&__item{
			float:left;
			margin-right: 25px;
			height: 48px;
			width: auto;
			padding: 13px 0 0 0;
			&:first-child{
				margin-left: 60px;
			}
			&:last-child{
				margin-right: 0;
				// margin-left: 30px;
			}
			&--social-media{
				margin-right: 50px;
			}
			&--about-us{
				// margin-left: 30px;
				margin-right: 50px;
			}
			&--join-team{
				// margin-left: 30px;
			}
			&--logo{
				padding: 0;
			}
			&__link{
				cursor: pointer;
				color: $superDarkGray;
				display: block;
				position: relative;
				font-size: 14px;
				font-weight: bold;
				
				text-align: center;
				&:first-child{
					// margin-left: 10px;
				}
				&:hover{
					color: $orange;
					text-decoration: none;
					cursor: pointer;
				}
			}
			& > .current{
				color: $orange;
			}
		}
	}
}

/**
 * Header Navigation
 */

.header__nav{
	width: 100%;
	background: url('../images/delseoul-logo.png') no-repeat center center scroll transparent;
	padding: 35px 0;
	overflow: hidden;
	margin: 24px 0 0 0;
	// display: none;
	&__list{
		@extend .no-list;
		overflow: hidden;
		&__item{
			float:left;
			margin-right: 0px;
			height: 36px;
			width: 131px;
			&__link{
				
				color: $lightGray;
				display: block;
				position: relative;
				font-size: 14px;
				font-weight: bold;
				padding: 8px 2px;
				text-align: center;
				&:hover{
					color: #FFF;
					text-decoration: none;
					cursor: pointer;
					background: url('../images/textures/white-border-header-nav.png') no-repeat center center scroll transparent;
				}
				
			}
			& > .current{
				color: #FFF;
				text-decoration: none;
				cursor: pointer;
				background: url('../images/textures/white-border-header-nav.png') no-repeat center center scroll transparent;	
			}
			&--special{
				margin-right: 195px;
			}
			&:first-child{
				margin-left: 10px;
			}
			&:last-child{
				margin-right: 0px;
			}
		}
	}
}

/**
 * Header Contact Info
 */

.header__contact-info{

	// display: none;
	margin: 5px auto 20px auto;
	width: 980px;
	overflow: hidden;
	&__section{
		float:left;
		margin: 20px 0 0 0;
		&--type{
			&-direction{
				width: 350px;
			}
			&-phone{
				float: right;
			}
			&-description{
				width: 170px;
				margin: 0 0 0 66px;
			}
		}
	}
	&__text{
		font-weight: bold;
		&--type{
			&-direction{
				color: $orange;
				background: url('../images/icons/geo-position-icon.png') no-repeat center left scroll transparent;
				padding-left: 23px;
				text-decoration: none;
				display: block;
				&:hover{
					color: $orange;
					text-decoration: none;
				}
				&:focus{
					color: $orange;
				}
			}
			&-description{
				color: $white;
			}
			&-phone{
				color: $orange;
				background: url('../images/icons/phone-small-icon.png') no-repeat center left scroll transparent;
				padding-left: 23px;
				text-decoration: none;
				display: block;
				&:hover{
					color: $orange;
					text-decoration: none;
				}
				&:focus{
					color: $orange;
				}
			}
		}

		&__resalt{
			font-family: 'Novecento Normal';
			font-weight: normal;
		}
	}
}

/**
 * Header Gallery
 */

.header__gallery{
	
	&__wrapper{
		width: 980px;
		height: 498px;
		overflow: hidden;
		position: relative;
		margin: 0 auto;
		border: 2px solid $white;
		// visibility: hidden;
	}

	&__nav-arrow{
		position: absolute;
		width: 68px;
		height: 100%;
		top: 0px;
		background-color: red;
		z-index: 3;
		
		&--left{
			left: 0px;
			background: url('../images/arrows/left-white-arrow.png') no-repeat center center scroll transparent;
		}

		&--right{
			right: -0px;
			background: url('../images/arrows/right-white-arrow.png') no-repeat center center scroll transparent;
		}
	}

	&__list{
		list-style: none;
		margin: 0;
		padding: 0;
		width: 980px;
		height: 498px;
	}

	&__item{
		position: relative;
		&__image{

		}

		&__text{
			@extend .open-sans;
			font-weight: bold;
			font-size: 22px;
			display: block;
			position: absolute;
			width: 100%;
			padding: 20px 0;
			text-align: center;
			bottom: -0px;
			background: rgba(0, 0, 0, 0.5);
		}
	}
}

/**
 * Header More Info - Schedules and social media links
 */

.header__more-info{
	
	width: 100%;
	margin-top: 36px;
	&__wrapper{
		width: 965px;
		overflow: hidden;
		position: relative;
		margin: 0 auto;
	}

	&__schedules{
		float: left;
		width: 400px;

		&__list{
			@extend .no-list;
			padding-left: 70px;
			background: url('../images/icons/open-icon.png') no-repeat left center scroll transparent;
		}

		&__item{
			font-weight: bold;
			@extend .novecento;
			&--type{
				&-days{
					color: $lightGray;
				}
				&-hours{
					color: $orange;
				}
			}
		}
	}

	&__social-media{
		float: right;
		width: 400px;

		&__list{
			@extend .no-list;
		}

		&__item{
			width: 53px;
			height: 53px;
			float: right;
			margin-left: 22px;
		}

		&__link{
			display: block;
			width: 53px;
			height: 53px;
			background: no-repeat center center scroll transparent;

			&--facebook{
				background-image: url('../images/icons/social-media/fb-white-icon.png');
			}

			&--twitter{
				background-image: url('../images/icons/social-media/twitter-white-icon.png');
			}

			&--instagram{
				background-image: url('../images/icons/social-media/instagram-white-icon.png');
			}
		}
	}
}

.header__order-online{
	&__link{
		color: #F9A541;
	    text-align: center;
	    font-size: 20px;
	    display: block;
	    font-weight: bold;
	    padding-top: 13px;
	    background: url("../images/textures/orange-rectangle-texture.png") no-repeat center center scroll transparent;
	    width: 216px;
	    height: 55px;
	    margin: 40px auto 0;
	    &:hover{
	    	color: #F9A541;
	    	text-decoration: none
	    }
	    &:focus{
	    	color: #F9A541;
	    	text-decoration: none
	    }
	}
}

/**
 * MAIN CONTENT
 */

.main{
	position: relative;
	z-index: 2;
}

// textures

.page-section{
	&__texture{
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		&--top-dual{
			width: 1210px;
			height: 160px;	
			background: url('../images/textures/top-dual-section-texture.png') no-repeat top center scroll transparent;
		}
	}
}

.page-section{
	min-height: 200px;
	width: 100%;
	
	position: relative;
	&__title{
		position: absolute;
	    z-index: 3;
	    top: -45px;
	    left: 0;
	    right: 0;
	    margin: auto;
		&--menu{
			width: 400px;
		    height: 88px;
		    background: url("../images/textures/del-seoul-back-sub-title-texture.png") no-repeat left center scroll transparent;
		}
		&--location{
			width: 495px;
		    height: 71px;
		    top: -42px;
		    background: url('../images/textures/del-seoul-back-sub-title-texture-2.png') no-repeat left center scroll transparent;
		}
		&--social-media{
			width: 410px;
		    height: 89px;
		    top: -45px;
		    background: url('../images/textures/del-seoul-back-sub-title-texture-3.png') no-repeat right center scroll transparent;
		}
		&--about-us{
			width: 495px;
		    height: 71px;
		    top: -42px;
		    background: url('../images/textures/del-seoul-back-sub-title-texture-2.png') no-repeat left center scroll transparent;
		}
		&--join-our-team{
			width: 410px;
		    height: 88px;
		    background: url('../images/textures/del-seoul-back-red-sub-title-texture.png') no-repeat left center scroll transparent;
		}
		&__text{
			// @extend .bebas-neue;
			@extend .dry-brush;
			font-size: 30px;
			color: $white;
			margin: 38px 0 0 180px;
			&--menu{
				margin: 38px 0 0 0;
				text-align: center;
			}
			&--location{
				margin: 18px 0 0 0;
				text-align: center;
			}
			&--social-media{
				margin: 35px 0 0 0;
				text-align: center;
			}
			&--about-us{
				margin: 18px 0 0 0;
				text-align: center;
			}
			&--join-our-team{
				margin: 38px 0 0 0;
				text-align: center;
			}
		}
	}

	&__background-image{
		position: absolute;
		z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	    text-align: center;
	    height:100%;
	    &__image{
	    	position: relative;
	    	width: 100%;
	    	margin:0 auto;
	    	background: no-repeat scroll center center;
	    	height: 100%;
	    	background-size: cover;
	    }
	}
	// &__background-image{
	// 	position: absolute;
	// 	z-index: 0;
	//     top: 0;
	//     left: 0;
	//     right: 0;
	//     margin: auto; 
	//     text-align: center;
	// }
}

/**
 * FOOD MENU SECTION
 */

.food-menu{
	width: 100%;	
	background: url('../images/textures/asphalt-texture.png') repeat top left scroll transparent;
	overflow: hidden;
	&__wrapper{
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
		background: url('../images/textures/top-dual-section-texture.png') no-repeat top center scroll transparent;
		overflow: hidden;
	}

	&__nav{
		width: 720px;
		margin: 70px auto 0;
		

		&__list{
			@extend .no-list;
		}
		&__item{
			float:left;
			:after{
				content: ">>";
			}
			margin-right: 25px;
			&:last-child{
				margin-right: 0px;
			}
		}
		&__link{
			display: block;
			color: #999999;
			text-decoration: none;
			font-weight: bold;
			font-size: 13px;
			
			padding-left: 25px;

			&:hover {
				color: #FFF;
				text-decoration: none;
				cursor: pointer;
			}

			&--current{
				color: #FFF;
				text-decoration: none;
			}

			&--type{
				&-vegetarian{
					background: url('../images/icons/restaurant/vegetarian-icon.png') no-repeat center left scroll transparent;
				}
				&-gluteen-free{
					background: url('../images/icons/restaurant/gluteen-free-icon.png') no-repeat center left scroll transparent;
				}
				&-spicy{
					background: url('../images/icons/restaurant/spicy-icon.png') no-repeat center left scroll transparent;
				}
				&-popular-item{
					background: url('../images/icons/restaurant/popular-item-icon.png') no-repeat center left scroll transparent;
				} 	
					
			}
		}
	}
}

.food-menu{
	&__main{
		overflow: hidden;	
		width: 900px;
		min-width: 800px;
		margin: 50px auto 0;
		min-height: 150px;
		&__columns{
			-webkit-column-count: 2;
			-webkit-column-gap: 10px;
			-webkit-column-fill: auto;
			-moz-column-count: 2;
			-moz-column-gap: 10px;
			-moz-column-fill: auto;
			column-count: 2;
			column-gap: 15px;
			column-fill: auto;
		}
		&__category{
			
			
			-webkit-column-break-inside: avoid;
			-moz-column-break-inside: avoid;
			column-break-inside: avoid;
			
			-webkit-transition: all .2s ease;
			-moz-transition: all .2s ease;
			-o-transition: all .2s ease;
			transition: all .2s ease;	
			margin: 0;
			&__title{
				@extend .bebas-neue;
				color: $orange;
				font-size: 38px;
			}

			&__description{
				@extend .open-sans;
				font-size: 14px;
				margin-top: 10px;
			}

			&__list{
				@extend .no-list;
			}

			&__food-item{
				overflow: hidden;
				background: url('../images/textures/orange-line-separator-texture.png') no-repeat left bottom scroll transparent;
				padding: 20px 0 24px 0;
				width: 400px;
			
				&:last-child{
					background: none;
				}

				&__top{
					overflow: hidden;
				}
			
				&__name{
					@extend .open-sans;
					// color: $darkGray;
					width: 325px;
					color: $white;
					font-size: 22px;
					font-weight: bold;
					float: left;
					cursor: pointer;
					padding-bottom: 2px;
					&:after{
						// content: " >"
					}
				}

				&__tags{
					float:left;
					margin-left: 12px;
					&__list{
						@extend .no-list;
						
					}
					&__item{
						float: left;
						margin-right: 8px;
					}
					&__image{
						display:block;
					}
				}

				&__price{
					@extend .open-sans;
					color: $white;
					font-size: 22px;
					font-weight: bold;
					float: right;
					&:before{
						content: "$";
					}
				}

				&__description{
					@extend .open-sans;
					clear: both;
					font-size: 14px;
					margin-top: 10px;
					display: none;
				}

				

			}
	
			&--type{
				&-cat-a{

					height: 400px;
					background-color: red;
				}	
				&-cat-b{
					height: 300px;
					background-color: black;
				}
				&-cat-c{
					height: 200px;
					background-color: yellow;
				}
				&-cat-d{
					height: 200px;
					background-color: blue;
				}
				&-cat-e{
					height: 100px;
					background-color: gray;
				}
			}
		}
	}

	&__drinks{
		margin-top: 100px;
		&--hidden{
			display: none;
		}
	}

	&__goto{
		overflow: hidden;
		&__section{
			background: url('../images/textures/bottom-left-page-texture.png') no-repeat left bottom scroll transparent;
			height: 177px;
			padding: 2px;
			margin-top: 20px;
			&--hidden{
				display: none;
			}
		}

		&__button{
			@extend .bebas-neue;
			color: $orange;
			text-align: center;
			font-size: 30px;
		    display: block;
		    font-weight: bold;
			padding-top: 7px;
			background: url('../images/textures/orange-rectangle-texture.png') no-repeat center center scroll transparent;
			width: 216px;
			height: 55px;
			margin: 0 auto;
			&--hidden{
				display: none;
			}
			&:hover{
				text-decoration: none;
				color: $orange;
				cursor: pointer;
			}
			&:visited{
				text-decoration: none;
				color: $orange;
				cursor: pointer;
			}
		}	
	}			
}

/**
 * Location
 */

.location{
	width: 100%;
	height: 585px;
	background: $backGray no-repeat center center scroll;
	&__wrapper{
		width: 990px;
		margin: 0 auto;
		overflow: hidden;
	}
	&__open{
		margin: 114px 0 0 20px;
		&__title{
			visibility: hidden;
			@extend .bebas-neue;
			font-size: 38px;
			width: 345px;
			padding: 0 0 10px 0;
			background: url('../images/textures/gray-line-separator-texture.png') no-repeat bottom center scroll transparent; 	
		}

		&__schedules{
			visibility: hidden;
			&__list{
				@extend .no-list;
				margin-top: 10px;
			}
			&__item{
				&__text{
					@extend .novecento;
					font-size: 19px;
					font-weight: bold;
					&--type{
						&-days{
							color: $lightGray;
						}
						&-hours{
							color: $orange;
						}
					}
				}
			}
		}
	} 

	&__info{
		margin: 165px 0 0 20px;
		overflow: hidden;
		visibility: hidden;
		&__contact{
			float: left;
			&__phone{
				@extend .novecento;
				display: block;
				font-size: 25px;
				font-weight: bold;
				padding: 0 0 0 33px;
				background: url('../images/icons/phone-medium-icon.png') no-repeat left center scroll transparent; 
				color: $white;
				text-decoration: none;
				&:hover{
					color: $white;
					text-decoration: none;
				}
				&:focus{
					color: $white;
				}

			}
			&__email{
				@extend .open-sans;
				display: block;
				font-size: 15px;
				font-weight: bold;
				padding: 0 0 0 33px;
				background: url('../images/icons/email-icon.png') no-repeat left center scroll transparent; 	
				color: $white;
				text-decoration: none;
				&:hover{
					color: $white;
					text-decoration: none;
				}
				&:focus{
					color: $white;
				}
			}
		}

		&__direction{
			float: right;
			width: 150px;
			margin-right: 170px;
			&__brand{
				@extend .novecento;
				display: block;
				font-size: 21px;
				font-weight: bold;
				color: $orange;
			}
			&__text{
				@extend .novecento;
				display: block;
				font-size: 14px;
				font-weight: bold;
				color: $lightGray;
				text-decoration: none;
				&:hover{
					color: $lightGray;
					text-decoration: none;
				}
				&:focus{
					color: $lightGray;
				}
			}
		}
	}
}

/**
 * SOCIAL MEDIA SECTION 
 */

.social-media{
	overflow: hidden;
	width: 100%;
	&__wrapper{
		@extend .open-sans;
		overflow: hidden;
		color: $darkGray;
		padding-top: 92px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
		background: url('../images/textures/top-right-section-texture.png') no-repeat top right scroll transparent;
		overflow: hidden;
	}

	&__tweets{
		&-wrap{
			position: relative;
		}
		&__name{
			font-size: 17px;
			color: $orange;
		}
	}

	&__section{
		width: 1000px;
		margin: 0 auto;
		&--top{
			overflow: hidden;
			background: url('../images/textures/section-separator-green-line.png') repeat-x bottom center scroll transparent;
		}

		&--links{

			float: left;
			width: 276px;
			background: url('../images/textures/social-media-icons-texture.png') no-repeat bottom left scroll transparent;
			padding-bottom: 60px;
			&__list{
				@extend .no-list;
			}
			&__item{
				float:left;
				margin-right: 20px;
				&:first-child{
					margin-left: 76px;
				}

				&--instagram{
					margin-right: 0px;
				}
					
				&:last-child{
					margin-right: 0px;
				}

				&__link{
					display: block;
					width: 53px;
					height: 53px;
					background: no-repeat center center scroll transparent;

					&--facebook{
						background-image: url('../images/icons/social-media/fb-black-icon.png');
					}

					&--twitter{
						background-image: url('../images/icons/social-media/twitter-yellow-icon.png');
					}

					&--instagram{
						background-image: url('../images/icons/social-media/instagram-green-icon.png');
					}
					&--share{
						background-image: url('../images/icons/social-media/share-red-icon.png');
						text-indent: -5000px;
					}
				}
			}
		}

		&--instagram{

			&__wrapper{
				width: 1000px;
				margin: 20px auto 0;
				overflow: hidden;
				height: 490px;
			}

		}


	}

	&__tweets-wrap{
		float: left;
		width: 325px;
	}
	
	&__share{
		float: right;
		width: 70px;
		&__item{
			width: 54px;
			height: 52px;
			&__link{
				&--share{	
					display: block;
					background: url('../images/icons/social-media/share-red-icon.png') no-repeat center center scroll transparent;
					width: 54px;
					height: 52px;
					text-indent: -5000px;
				}
			}
		}
	}
}

// Social media yelp-newsletter section

.social-media__section{
	&--yelp-newsletter{
		overflow: hidden;
		margin: 56px auto 0 auto;
		&__header{
			overflow:hidden;
			// width: 955px;
			margin: 0 auto;

		}
		&__body{
			overflow:hidden;
			margin: 25px 0 0 0;
		}
		
	}

	&__yelp-business{
		overflow: hidden;
		width: 320px;
		float:left;
		&__logo{
			&__link{
				display: block;
			}
			&__image{}
		}
		&__info{

			margin: 20px 0 0 5px;
			&__rating{
				&__from{
					margin-top: 6px;
					&__resalt{
					}
				}
			}
			&__reviews{
				margin: 5px 0 0 0;
				&__text{
					@extend .open-sans;
					color: $darkGray;
					font-size: 14px;
					&--resalt{
						font-weight: bold;
						color: $superDarkGray;
					}
				}
			}
		}
	}

	&__google-business{
		overflow: hidden;
		width: 260px;
		float:left;
		padding-top: 15px;
		&__logo{
			&__link{
				display: block;
			}
			&__image{}
		}
		&__info{
			margin: 5px 0 0 15px;
			&__raiting{}
			&__reviews{
				margin: 5px 0 0 0;
				&__text{
					@extend .open-sans;
					color: $darkGray;
					font-size: 14px;
					&--resalt{
						font-weight: bold;
						color: $superDarkGray;
					}
				}
			}
		}
	}

	&__newsletter{
		width: 390px;
		margin: 0 0 0 30px;
		float:right;
	}

	&__yelp-reviews{
		&__list{
			overflow: hidden;
		}
		&__item{
			visibility: hidden;
			&__excerpt{
				padding: 23px 0 0 28px;
				background: url('../images/yelp/yellow-double-quotes.png') no-repeat top left scroll transparent;
				&__text{
					color: $darkGray;
					font-size: 14px;
				}
			}
			&__profile{
				margin: 20px 0 0 28px;
				&__info{
					&__name{
						margin: 0 0 6px 0;
						&__link{
							display: block;
							color: $superDarkGray;
							font-size: 17px;
							font-weight: bold;
							&:hover{
								text-decoration: none;
								color: $superDarkGray;
							}
							&:focus{
								text-decoration: none;
								color: $superDarkGray;	
							}
						}
					}
				}
			}
		}
		&__view-more{
			// width: 220px;
			margin: 50px auto 0;
			overflow: hidden;
			text-align: center;
			&__link{
				@extend .bebas-neue;
				color: $superDarkGray;
				font-size: 25px;
				display: block;
				text-align: center;
				padding: 10px 3px;
				background: url('../images/textures/yellow-medium-border.png') no-repeat center center scroll transparent;
				&:hover{
					text-decoration: none;
					color: $superDarkGray;
				}
				&:focus{
					text-decoration: none;
					color: $superDarkGray;	
				}
			}
		}
	}
	
	&--bottom-texture{
		width: 1210px;
		margin: 0 auto;
		background: url('../images/textures/bottom-left-page-texture.png') no-repeat left bottom scroll transparent;
		overflow: hidden;
		height: 135px;
	}
}


// Rotating tweets

.rotatingtweets{
	
	.rotatingtweet{
		
		.rtw_main{
			font-size: 14px;
			
		}

		.rtw_meta{
			font-size: 12px;
			color: $mediumGray;	
			// position: absolute;
			// top: 4px;
			// right: 12px;
			text-align: right;
			position: relative;
			display: block;
			margin: 0 15px 0 0;
			& > a {
				color: $mediumGray;
				font-size: 12px;
				&:hover{
					text-decoration: none;
					color: $mediumGray;
				}
			}
		}
	}

}

// styles for instagram photos section

.si_feed{
	overflow: hidden;
	overflow-y: scroll;
	// height: 822px;
	height: 100%;
	.si_item{
		// width: 33.33333333%;
		text-align: center;
		float: left;
		margin: 0 0 20px 0;
		padding: 0 10px;
		min-width: 50px;
		min-height: 230px;
		& > a{
			& > img{
				display: none;
			}
		}
	}
}

// Instagram Feed Worpress Plugin

#sb_instagram{
	#sbi_images{
		.sbi_item{
			min-height: 243px;
			.sbi_photo_wrap{
				// display: none;
			}
		}
	}
}

// Mailchimp custom newsletter styles

#mc_embed_signup{
	@extend .open-sans;
	overflow: hidden;
	.mc_embed_signup_scroll{
		overflow: hidden;
	}
	.mc-field-group{
		overflow: hidden;
		width: 278px;
		float: left;
		.email{
			
			font-size: 17px;
		    width: 280px;
		    border: 0;
		    background: url("../images/textures/newsletter-back.png") no-repeat center center scroll transparent;
		    padding: 17px 2px 17px 15px;
		    float:left;
		}
		div.mce_inline_error{
			float:left;
			background-color: none;
			display: none !important;

		}
	}

	.clear{
		float:left;
		#mc-embedded-subscribe{
			background: url("../images/newsletter-submit-button.png") no-repeat center center scroll transparent;
			width: 109px;
			height: 57px;
			border: 0;
		}	
	}

	#mce-responses{
		margin: 5px 0 0 0;
		.response{
			font-size: 12px;
			padding: 0 5px;
		}
	}
	
}

// ABOUT US SECTION

.about-us{
	width: 100%;
	background: no-repeat top center scroll; 
	min-height: 735px;
	overflow: hidden;
	padding-bottom: 100px;
	background-size: cover;
	&__wrapper{
		width: 990px;
		margin: 110px auto 0;
		overflow: hidden;
	}
}

.about-us__who-we-are{
	width: 350px;
	float: left;
	margin: 0 0 0 25px;
	background-color: $orange;
	padding: 20px 0;
	&__title{
		@extend .bebas-neue;
		font-size: 38px;
		padding: 0 0 16px 19px;
		background: url('../images/textures/black-medium-line-separator-texture.png') no-repeat bottom center scroll transparent;
	}

	&__info{
		margin: 31px 25px 0;
		&__text{
			@extend .open-sans;
			font-size: 14px;
		}
	}
}

.about-us__our-team{
	width: 580px;
	float: left;
	margin: 0 0 0 19px;
	background-color: $orange;
	padding: 20px 0;
	&__title{
		@extend .bebas-neue;
		font-size: 38px;
		padding: 0 0 16px 19px;
		background: url('../images/textures/black-large-line-separator-texture.png') no-repeat bottom center scroll transparent;
	}

	&__list{
		@extend .no-list;
		margin: 0 17px;
	}

	&__item{
		overflow: hidden;
		padding: 31px 0 0 0;
		&__image-wrapper{
			width: 190px;
			border-top: 4px solid #333333;
			float: left;
			position: relative;
		}
		&__image{
			position: relative;
			overflow: hidden;
			&__text{
				@extend .dry-brush;
				position: absolute;
				font-size: 34px;
				bottom: 0;
				right: 5px;
			}
		}
		&__bio-wrapper{
			width: 320px;
			float: left;
			margin: 0 0 0 30px;
		}
		&__bio-text{
			@extend .open-sans;
			font-size: 14px;
		}

		&:first-child{
			padding-bottom: 35px;
			background: url('../images/textures/white-line-about-us.png') no-repeat bottom center scroll;
		}
	}

}

// JOIN OUR TEAM SECTION

.join-our-team{
	width: 100%;
	// background: rgba(0,0,0, .7) url('../images/del-seoul-contact-back-image.png') no-repeat center center scroll;
	background: rgba(0,0,0,.5) no-repeat top center scroll;
	height: auto;
	overflow: hidden;
	position: relative;
	padding-bottom: 30px;
	&__wrapper{
		width: 990px;
		margin: 110px auto 0;
		overflow: hidden;
	}

	&__our-concept{
		// width: 350px;
		// float: left;
		// margin: 0 0 0 25px;
		&__title{
			@extend .bebas-neue;
			font-size: 38px;
			padding: 0 0 10px 19px;
			background: url('../images/textures/red-medium-line-separator-texture.png') no-repeat bottom center scroll transparent;
		}

		&__description{
			margin-top: 10px;
			@extend .open-sans;
		}

		&__info{
			margin: 31px 0 0 0;
			&__text{
				@extend .open-sans;
				font-size: 14px;
				padding: 0 19px;
			}
		}
	}

	&__contact{
		// width: 390px;
		// float: left;
		// margin: 0 0 0 160px;
		&--join-our-team{
			float:right;
		}
	}
}

// contact form



.join-our-team__contact__form{
	&__group{
		@extend .open-sans;
		margin-bottom: 10px;
		overflow: hidden;
		margin: 20px 0 0 0;
		&__label{}
		&__submit{
			@extend .dry-brush;
			font-size: 35px;
			text-align: center;
			background: url('../images/textures/send-button-texture.png') no-repeat bottom center scroll transparent;
			padding: 0 0 11px 0;
			border: none;
			outline: none;
			width: 120px;
			margin: 0 0 0 140px;
			float:left;
		}
		& > .ajax-loader{
			float: left;
			border: 1px solid #d3d3d3;
			background-color: #f9f9f9;
		    border-radius: 3px;
		    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.08);
    		-webkit-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.08);
    		padding: 5px;
    		margin: 13px 0 0 0;
		}

		&__input{
			color: #FFF;
			background: none;
			border: none;
			padding: 6px 6px 6px 12px;
			font-size: 14px;
			background: url('../images/textures/input-border-texture.png') no-repeat center center scroll transparent;
			width: 382px;
			height: 48px;
			outline: none;
			&::-webkit-input-placeholder{  
				color: #FFF;
   				font-size: 17px;
			}
			&:-moz-placeholder{
				color: #FFF;
				font-size: 17px;
			}
			&::-moz-placeholder{
				color: #FFF;
				font-size: 17px;
			} 
			&:-ms-input-placeholder{
				color: #FFF;
				font-size: 17px;
			}

		}
		&__select{
			color: #FFF;
			background: none;
			border: none;
			padding: 6px 6px 6px 9px;
			font-size: 17px;
			background: url('../images/textures/input-custom-border-texture.png') no-repeat center center scroll transparent;
			width: 382px;
			height: 48px;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
		    appearance: none;
			&--custom-arrow{
			   width: 240px;
			   height: 32px;
			   overflow: hidden;
			   border: 1px solid #ccc;
			}

			& > option{
				color: $darkGray;
				background-color: transparent;
				border: 0;
				padding: 15px 10px;
				font-size: 17px;
				outline: none;
			}
		}
		&__option{
			color: $darkGray;
			background-color: transparent;
			border: 0;
			padding: 15px 10px;
			font-size: 17px;
			outline: none;
		}
		&__recaptcha{
			width: 304px;
			margin: 0 auto;
		}
		&__textarea{
			color: #FFF;
			background: none;
			border: none;
			padding: 12px;
			font-size: 14px;
			background: url('../images/textures/textarea-border-texture.png') no-repeat center center scroll transparent;
			width: 382px;
			height: 192px;
			outline: none;
			&::-webkit-input-placeholder{  
				color: #FFF;
   				font-size: 17px;
			}
			&:-moz-placeholder{
				color: #FFF;
				font-size: 17px;
			}
			&::-moz-placeholder{
				color: #FFF;
				font-size: 17px;
			} 
			&:-ms-input-placeholder{
				color: #FFF;
				font-size: 17px;
			}
		}

	}
}

// cutsom styles for CF7 plugin

.join-our-team__contact{

	& .wpcf7-response-output{
		margin: 10px 0 0 0;
	}
	& .wpcf7-validation-errors{
		@extend .novecento;
		color: $red;
		border: 2px dashed $orange;
		font-size: 12px;
		font-weight: bold;
	}
	& .wpcf7-spam-blocked{
		@extend .novecento;
		color: $red;
		border: 2px dashed $orange;
		font-size: 12px;
		font-weight: bold;

	}
	& .wpcf7-mail-sent-ok{
		@extend .novecento;
		color: $white;
		border: 2px dashed $orange;
		font-size: 12px;
		font-weight: bold;

	}
	& span.wpcf7-not-valid-tip{
		@extend .novecento;
		color: $red;
		font-size: 12px;
		padding: 3px 0;
		font-weight: bold;
	}
}

@media only screen and (max-width: 800px){
	
	.hide-if-is-mobile{
		display: none !important;
	}

	.show-if-is-mobile{
		display: block;
	}

	.header{
		&__bg-image__wrap{
			height: 100%;
		}
		&__bg-image{
			height: 100%;
		}
	}
	
	.header{
		height: auto;
		font-size: 13px;
		&__background-image{
			height: auto;			
		}
		&__nav{
			padding: 10px 0 90px 15px;
			margin: 10px 0 0 0;
		}
		
		&__wrapper{
			width: 100%;
			height: auto;
			padding-bottom: 100px;
		}

		&__contact-info{
			width: 95%;
			margin-top: 0;
			&__text{
				&--type-direction{
					padding-top: 2px;
				}
				&--type-description{
					margin: 0 0 20px 0;
					text-align: center;
				}
			}
			&__section{
				float: none;
				margin: 7px auto;
				&--type-direction{
					width: 315px;
				}
				&--type-phone{
					float: none;
					width: 131px;
				}
			}
		}

		&__gallery{
			
			&__wrapper{
				width: 100%;
				height: auto;	
				border: 0;
			}
			&__list{
				width: 100%;
				height: auto;	
			}
			&__item{
				
			}
		}

		&__more-info{
			width: 100%;
			&__wrapper{
				width: 95%;
			}

			&__schedules{
				float: none;
				width: 300px;
				margin:0 auto;
			}
			&__social-media{
				float: none;
				width: 220px;
				margin: 25px auto 0;
				&__item{
					margin-left: 0px;
					float:left;
					margin-right: 20px;
				}
			}
		}
	}

	.burger-nav-button{
		position: fixed;
		width: 47px;
		z-index: 11;
		background-image: none;
		background-color: rgba(255, 255, 255, 0.9);
		padding: 10px;

		&--with-logo{
			//background: rgba(255, 255, 255, 0.9) url('../images/delseoul-mini-logo.png') no-repeat center center scroll;	
		}
	
		& .white-burger{
			display: block;
		}
		& .black-burger{
			display: block;
		}
	}

	// mobile navigation

	.header__nav__list--mobile{
		list-style: none;
		display: none;
		position: fixed;
		width: 100%;
		background: rgba(255, 255, 255, 0.9) url('../images/delseoul-mini-logo.png') no-repeat top center scroll;	
		z-index: 10;
		margin: 0; padding: 0;
		// top: 46px;
		padding: 45px 0 0 0;
		&__item{
			padding: 10px 0;
			&__link{
				cursor: pointer;
				font-family: 'Novecento Bold';
				color: $superDarkGray;
				display: block;
				position: relative;
				font-size: 14px;
				font-weight: bold;
				text-decoration: none;
				text-align: center;

				&:hover{
					color: $orange;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}
	}

	// food menu

	.food-menu{
		&__wrapper{
			width: 100%;
			max-width: 1210px;
		}
		&__main{
			overflow: hidden;	
			width: 100%;
			max-width: 900px;
			min-width: 320px;
			margin: 20px 0 0 0;
			&__columns{
				-webkit-column-count: 1;
				-webkit-column-gap: 0;
				-webkit-column-fill: auto;
				-moz-column-count: 1;
				-moz-column-gap: 0;
				-moz-column-fill: auto;
				column-count: 1;
				column-gap: 0px;
				column-fill: auto;
				width: 95%;
				margin:0 auto;
			}
			&__category{
				width: 100%;
				margin: 10px 0 10px 0;
				&__title{
					font-size: 25px;
				}

				&__food-item{
					width: 100%;
					padding: 10px 0;

					&__name{
						font-size: 15px;
					}
					&__description{
						font-size: 13px;
						margin-top: 6px;
					}
					&__price{
						font-size: 13px;
					}
				}
			}

		}
		&__nav{
			width: 350px;
			&__list{

			}
			&__item{
				float: none;
				display: inline-block;
				margin: 5px auto 5px;
			}
		}

		&__goto{
			&__section{
				width: 100%;
				
			}
		}
	}

	.location{
		background: url('../images/delseoul-location-cut-map.png') no-repeat right center scroll transparent !important;
		&__wrapper{
			width: 100%;
			margin: 0 auto;
			overflow: hidden;
		}
		&__open{
			width: 95%;
			margin: 50px auto 0;
			&__title{
				font-size: 28px;
			}
			&__schedules{
				&__item{
					&__text{
						font-size: 14px;
					}
				}
			}
		}

		&__info{
			margin: 255px 0 0 0;
			&__contact{
				float:none;
				width: 95%;
				margin: 0 auto;
				&__phone{
					font-size: 14px;
					margin-bottom: 6px;
				}
				&__email{
					font-size: 14px;
				}
			}
			&__direction{
				float: none;
				width: 95%;
				margin: 0 auto 12px;

				&__brand{
					font-size: 14px;
				}
			}
		}		
	}

	.social-media{
		&__wrapper{
			width: 100%;
			padding-top: 80px;
		}

		&__section{
			width: 100%;
			&--top{

				width: 100%;
				background: none;
			}

			&__yelp-business{
				margin: 0 auto;
				float:none;
			}

			&__yelp-reviews{
				&__view-more{
					&__link{
						margin-bottom: 12px;
					}	
				}
			}

			&__google-business{
				width: 315px;
				margin: 0 auto;
				float:none;
			}

			&__google-reviews{
				&__view-more{
					&__link{
						margin-bottom: 12px;
					}	
				}
			}

			&__newsletter{
				margin: 0 auto;
				width: 305px;
				float:none;
			}

			&--links{
				background: none;
				padding: 0;
				margin: 25px auto 0;
				float: none;
				width: 280px;
				&__item{
					&--instagram{
						margin-right: 20px;
					}
					&:first-child{
						margin-left: 0;
					}
				}
			}
		}

		&__tweets-wrap{
			float:none;
			width: 300px;
			margin: 0 auto;
		}

		&__section--instagram{
			width: 100%;
			height: auto;
			&__wrapper{
				width: 100%;
				height: auto;
				margin-top: 50px;
				min-height: auto;
			}
		}

		&__section--yelp{
			&-newsletter{
				&__header{
					width: 100%;
					margin: 0 auto;
				}
			}
		}

		&__section__yelp{
			&-business{
				&__info{
					margin-left: 5px;
					&__reviews{
						&__text{
							font-size: 13px;
						}
					}
				}
			}
		}

	}

	#mc_embed_signup{
		.mc-field-group{
			overflow: hidden;
			width: 192px;
			float: left;
			.email{
				
			    width: 192px;
			    background: url("../images/textures/newsletter-input-mobile.png") no-repeat center center scroll transparent;
			    padding: 17px 2px 17px 15px;
			}
			div.mce_inline_error{
			}
		}

		.clear{
			#mc-embedded-subscribe{
			}	
		}

		#mce-responses{
			.response{
			}
		}
		
	}

	// Tweets

	.rotatingtweets{
		.rotatingtweet{
			.rtw_main{
				font-size: 13px;
			}
		}
	}

	// instragram plugin

	.si_feed{
		height: auto;
		width: 95%;
		max-width: 95%;
		margin: 0 auto;
		.si_item{
			width: 32%;
			margin-right: 1%;
			margin-top: 5px;
			> a {
				width: 100%;
				height: auto;
				> img{
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.about-us{
		min-height: auto;
		padding: 0 0 70px 0;
		&__wrapper{
			width: 100%;
			margin: 50px auto 0;
		}
	}

	.about-us__who-we-are{
		width: 100%;
		margin: 0 auto;
		float:none;
		&__title{
			width: 290px;
			font-size: 28px;
			margin-left: 3%;
			padding: 0 0 10px 10px;
		}

		&__info{
			width: 95%;
			margin: 25px auto 0;
			&__text{
				font-size: 13px;
			}
		}
	}

	.about-us__our-team{
		width: 100%;
		float: none;
		margin: 20px auto 0;
		overflow: hidden;
		&__title{
			width: 290px;
			font-size: 28px;
			margin-left: 3%;
			padding: 0 0 10px 10px;
		}
		&__list--mobile{
			overflow: hidden;
			list-style: none;
			padding: 0;
			margin: 31px auto 0;
			width: 300px;
		}

		&__item--mobile{
			float:left;
			padding: 0 5px 8px 5px;
			&:first-child{
				margin: 0 8px 0 8px; 
			}
			&__image-wrapper{
				width: 130px;
				height: 112px;
				position: relative;
			}
			&__image{
				width: 130px;
				height: 112px;
				&__text{
					font-family: "Dry Brush";
					position: absolute;
					font-size: 34px;
					bottom: 0;
					right: 5px;
					z-index: 1;
				}
			}
			&__image-cover{
				background: rgba(0, 0, 0, 0.7); 
				height: 100%;
				width: 100%;
				position: absolute;
				z-index: 2;
				top:0;
				left:0;
			}

			&--current{
				background: url('../images/textures/our-team-member-texture.png') no-repeat bottom center scroll transparent;
				.about-us__our-team__item--mobile__image-cover{
					display: none;
				}
			}
		}

		&__bio-list--mobile{
			overflow: hidden;
			list-style: none;
			padding: 0;
			margin: 0 auto;
			width: 286px;

		}

		&__bio-item{
			display: none;
			&__bio-wrapper{
				width: 95%;
				margin: 20px auto 0;
			}
			&__bio-text{
				font-family: "Open Sans";
				font-size: 13px;
				width: 100%;

			}

			&--current{
				display: block;
			}
		}
	}

	.join-our-team{
		height: auto;
		&__wrapper{
			width: 100%;
		}
		&__our-concept{
		    width: 95%;
		    margin: 0 auto 30px auto;
			float:none;
		    &__title{
		    	width: 290px;
				font-size: 28px;
				margin-left: 3%;
				padding: 0 0 10px 10px;
		    }

		    &__info{
		    	margin: 20px 0 0 0;
		    }
		}

		&__contact{
			width: 320px;
			float:none;
		    margin: 0 auto 25px;	

		    &__form__group{
		    	&__input{
		    		background: url('../images/textures/input-border-mobile.png') no-repeat center center scroll transparent;
					width: 290px;
					height: 48px;
		    	}

		    	&__select{
		    		background: url('../images/textures/input-custom-border-mobile.png') no-repeat center center scroll transparent;
					width: 290px;
		    	}

		    	&__textarea{
		    		background: url('../images/textures/textarea-border-mobile.png') no-repeat center center scroll transparent;
					width: 290px;
					height: 150px;
		    	}

		    	&__submit{
		    		margin-left: 80px;
		    	}
		    }		
		}
	}
}


@media only screen and (max-width: 420px){

	.hide-if-is-small-screen{
		display: none !important;
	}

	// header

	.header{
		&__gallery{
			
			&__item{
				&__text{
					font-size: 13px;
					padding: 10px 0;
				}
			}
			&__nav-arrow{
				width: 40px;
				&--left{
					background: url('../images/arrows/mini-left-white-arrow.png') no-repeat center center scroll transparent;
				}

				&--right{
					background: url('../images/arrows/mini-right-white-arrow.png') no-repeat center center scroll transparent;
				}
			}
		}
	}
	.page-section{
		
		&__title{
			position: absolute;
		    z-index: 3;
		    top: -45px;
		    left: 0;
		    right: 0;
		    margin: auto;
			&--menu{
				width: 100%;
			    background-position: -25px center;
			}
			&--location{
				width: 100%;
			    background-position: -65px center;
			}
			&--social-media{
				width: 100%;
				background-position: 25px center;
			}
			&--about-us{
				width: 100%;
			    background-position: -65px center;
			}
			&--join-our-team{
				width: 100%;
			    background-position: -25px center;
			}
		}
	}
}